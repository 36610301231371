<template>
  <div class="reviews">
    <div class="wrapper-content">
      <h3 class="reviews__title">Отзывы</h3>
      <div class="reviews__slider">
        <VueSlickCarousel
          :slides-to-show="2"
          :infinite="false"
          :responsive="[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
          ]"
        >
          <div
            v-for="({ city, name, desc }, index) in reviews"
            :key="index"
            class="review reviews__review"
          >
            <p class="review__city">
              {{ city }}
            </p>
            <p class="review__name">
              {{ name }}
            </p>
            <div class="review__desc">
              <p v-for="(item, indexItem) in desc" :key="indexItem">
                {{ item }}
              </p>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "Reviews",
  data() {
    return {
      reviews: [
        {
          city: "Москва",
          name: "Евгений Иванов",
          desc: [
            "Вашим сервисом пользуюсь не первый раз, все удобно и понятно. Не забывайте вернуть займ вовремя.",
          ],
        },
        {
          city: "Ростов на Дону",
          name: "Дмитрий Краснов",
          desc: [
            "Ответы по заявке пришли мгновенно. Выбрал для себя подходящее предложение и сразу получил деньги.",
          ],
        },
        {
          city: "Москва",
          name: "Евгений Иванов",
          desc: [
            "Вашим сервисом пользуюсь не первый раз, все удобно и понятно. Не забывайте вернуть займ вовремя.",
          ],
        },
        {
          city: "Ростов на Дону",
          name: "Дмитрий Краснов",
          desc: [
            "Ответы по заявке пришли мгновенно. Выбрал для себя подходящее предложение и сразу получил деньги.",
          ],
        },
      ],
    };
  },
  components: {
    VueSlickCarousel,
  },
};
</script>
