var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviews"},[_c('div',{staticClass:"wrapper-content"},[_c('h3',{staticClass:"reviews__title"},[_vm._v("Отзывы")]),_c('div',{staticClass:"reviews__slider"},[_c('VueSlickCarousel',{attrs:{"slides-to-show":2,"infinite":false,"responsive":[
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          } ]}},_vm._l((_vm.reviews),function(ref,index){
        var city = ref.city;
        var name = ref.name;
        var desc = ref.desc;
return _c('div',{key:index,staticClass:"review reviews__review"},[_c('p',{staticClass:"review__city"},[_vm._v(" "+_vm._s(city)+" ")]),_c('p',{staticClass:"review__name"},[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"review__desc"},_vm._l((desc),function(item,indexItem){return _c('p',{key:indexItem},[_vm._v(" "+_vm._s(item)+" ")])}),0)])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }